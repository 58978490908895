:root {
    --white: #fff;
    --white-muted: #eae9e9;
    --gray: #e3e3e3;
    --green-start: hsl(105deg 49% 24%);
    --blue-active: hsl(211deg 28% 25%);
    --blue-active-90: hsl(211deg 28% 25% / 90%);
    --blue-dark-bg: hsl(210deg 38% 15%);
    --blue-action: hsl(213deg 40% 49%);
    --blue-action-90: hsl(213deg 40% 49% / 90%);
    --blue-muted: hsl(213deg 23% 25%);
    --blue-dark: hsl(211deg 43% 10%);
    /* --accent: hsl(293deg 50% 30%); */
    --accent-purple: hsl(280deg 35% 35%);
    --accent: hsl(340deg 75% 23% / 50%);
    --accent-light: hsl(340deg 53% 42%);
    --accent-active: hsl(340deg 71% 39% / 50%);
    --accent-dark: hsl(340deg 79% 25%);
    --accent-dark-90: hsl(340deg 79% 25% / 90%);
    --swipe-progress: 0;
}

*,
::before,
::after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

#root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

body {
    background: var(--blue-dark-bg);
    color: var(--white);
}

a {
    color: inherit;
    text-decoration: none;
}

input {
    border: 0;
    font-size: 1rem;
    font-family: 'Rubik', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;

    background: transparent;

    /* inherit font & color from ancestor */
    color: inherit;
    font: inherit;

    /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
    line-height: normal;

    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    /* Corrects inability to style clickable `input` types in iOS */
    -webkit-appearance: none;
}

/* Remove excess padding and border in Firefox 4+ */
button::-moz-focus-inner {
    border: 0;
    padding: 0;
}

/* Custom scrollbar */

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #444;
}

.swiper-pagination-progressbar-fill {
    background: var(--blue-action) !important;
    border-radius: 99px;
    transform: translate3d(0px, 0px, 0px) scaleY(1) scaleX(var(--swipe-progress)) !important;
}
